import React, { useState } from "react"
import {
  Box,
  Text,
  Button,
  useColorModeValue,
  FormControl,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import firebase from "gatsby-plugin-firebase"
import ResponsiveBlock from "../shared/responsive-block"

const CreateBlogPosts = () => {
  const textColor = useColorModeValue("#171F2B", "white")

  const toast = useToast()

  const [title, setTitle] = useState("")
  const [author, setAuthor] = useState("")
  const [date, setDate] = useState("")
  const [content, setContent] = useState("")
  var db = firebase.firestore()

  function addBlog() {
    db.collection("Blogs")
      .doc(title)
      .set({ title: title, author: author, date: date, content: content })
      .then((docRef) => {})
      .catch((error) => {})
  }

  return (
    <Box h="1000px" align="center">
      <ResponsiveBlock>
        <Box h="100px" align="center" w="100%" mt={10}>
          <Text color={textColor} fontSize="60px" fontWeight={70}>
            Blog
          </Text>
        </Box>
        <Box h="800px" w="100%" mb={5}>
          <Box padding={5}>
            <FormControl>
              <Input
                h="50px"
                fontSize="40px"
                placeholder={"Title"}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Input>
            </FormControl>
          </Box>
          <Box padding={5}>
            <FormControl>
              <Input
                h="50px"
                variant="flushed"
                fontSize="20px"
                placeholder={"Author"}
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              ></Input>
            </FormControl>
          </Box>
          <Box padding={5}>
            <FormControl>
              <Input
                h="50px"
                variant="flushed"
                fontSize="20px"
                placeholder={"Date"}
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              ></Input>
            </FormControl>
          </Box>
          <Box padding={5}>
            <FormControl>
              <Textarea
                variant="outline"
                h="500px"
                fontSize="20px"
                placeholder={"Content"}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></Textarea>
            </FormControl>
          </Box>
        </Box>
        <Button
          onClick={() => {
            addBlog()
            toast({
              title: "Blog created.",
              description: "Your blog has been successfully added.",
              status: "success",
              duration: 9000,
              isClosable: true,
            })
          }}
        >
          Post Blog
        </Button>
      </ResponsiveBlock>
    </Box>
  )
}

export default CreateBlogPosts
