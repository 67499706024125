import React from "react"

import { Box } from "@chakra-ui/react"
import CreateBlogPosts from "~components/blog/create-blog-posts"

const CreateBlog = () => {
  return (
    <Box>
      <CreateBlogPosts />
    </Box>
  )
}

export default CreateBlog
